<template>
  <div class="home-bg">
    <navigation-buttons isPage="home" />
    <div class="main-page">
      <div class="codeme"></div>
      <div class="main-text">
        <h1>SPIROS KARATHANASSIS</h1>
        <span>SOFTWARE ENGINEER</span>
      </div>
      <div class="location-container">
        <img src="@/assets/images/worldwide.webp" alt="location" width="24" />
        <a href="https://www.google.com/maps/place/Patras">Patras, Greece</a>
      </div>

      <div class="socials">
        <div class="tag">
          <a
            rel="noopener"
            href="https://www.linkedin.com/in/spiroskarathanassis/"
            target="_blank"
          >
            <img
              src="@/assets/socials/linkedin.svg"
              alt="linkedin"
              width="24"
            />
          </a>
        </div>
        <div class="tag">
          <a
            rel="noopener"
            href="https://github.com/spiroskarathanassis"
            target="_blank"
          >
            <img src="@/assets/socials/github.svg" alt="github" width="24" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationButtons from "../sections/NavigationButtons.vue";

export default {
  name: "HomePage",
  components: { NavigationButtons },
};
</script>

<style lang="sass">
$white: #fff
$calmWhite: #E5E5E5
$hoverBg: rgba(255, 255, 255, 0.25)

@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

@mixin flex-column
  @include flex-center
  justify-content: flex-start
  flex-direction: column

.home-bg
  background: linear-gradient(360deg, rgb(34 34 34 / 30%) 4.73%, rgba(0, 0, 0, 0) 93.93%), url('../../assets/images/home-bg.webp') no-repeat center 100%/cover rgba(0,0,0,0.2)
  background-blend-mode: darken
  min-height: 100vh

  .main-page
    @include flex-column
    width: 100%
    color: $white

    .codeme
      background: url('../../assets/images/codeme.webp') no-repeat center 100%/contain
      width: 100%
      height: 60vh

    .main-text
      @include flex-column

      h1
        color: $calmWhite
        text-align: center
      span
        padding: 0.4rem
        letter-spacing: 2px
        color: $calmWhite
        font-weight: bold

    .location-container
      background-color: $hoverBg
      backdrop-filter: blur(2px)
      border-radius: 30px
      width: 10rem
      height: 2rem

      img
        width: 2rem

      a
        color: $calmWhite
        margin-left: 10px
        margin-top: 10px
        font-family: cursive
        vertical-align: top
        line-height: 2rem

    .socials
      @include flex-center
      margin-top: 1rem
      padding: 1rem 0

      .tag
        margin: 0 0.5rem

        a:hover
          opacity: 0.7
</style>
