<template>
  <div id="navbar" 
    :class="{'nav-container': !isHomePage && isMenuOpened, 'nav-opened': isNavbarActive && isMenuOpened}"
    @click="handleFocus"
  >
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/logo.svg" alt="logo" width="32">
      </router-link>
    </div>
    <div class="control-buttons" :class="{buttonsArea: !isHomePage && isMobile}">
      <h2 @click="startMenuClosed">Menu</h2>
      <ul v-show="isMenuOpened" :class="{mobileDrop: !isHomePage && !isNavbarActive}">
        <li v-for="(route, index) in routes" :key="index">
          <router-link :to="route.to">{{ route.text }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import detectMobile from '../../utils/detectMobile';

const menuElements = {
  home      : 'Home',
  portfolio : 'Portfolio',
  bio       : 'Bio',
}

export default {
  name: 'NavigationButtons',
  props: [ 'isPage' ],
  setup (props) {
    const routes = reactive([]);
    const isHomePage = ref(false);
    const isNavbarActive = ref(false);
    const isMenuOpened = ref(true);
    const isMobile = ref(false);
    
    const setRoutes = () => {
      for (const el in menuElements) {
        if ((props.isPage === 'home' && el === 'home')) {
          isHomePage.value = true;
          continue;
        }

        let path = `/${el}`;
        if (el === 'home') path = '/';
        if (el === props.isPage) path = '#';

        routes.push({
          to: path, 
          text: menuElements[el]
        })
      }
    }

    window.addEventListener('resize', () => detectWidth());

    const handleFocus = e => {
      if (isNavbarActive.value && isMenuOpened.value && e.target.id == 'navbar') {
        isMenuOpened.value = false;
      }
    }
    const detectWidth = () => {
      isMobile.value = (window.innerWidth < 400) || detectMobile();
      if (isMobile.value) {
        isMenuOpened.value = false;
      }
    }
    const startMenuClosed = () => {
      isMenuOpened.value = !isMenuOpened.value;
      isNavbarActive.value = true;
    };

    onBeforeMount(() => {
      setRoutes();
      detectWidth();
    });

    return {
      routes,
      isHomePage,
      isMenuOpened,
      isNavbarActive,
      isMobile,
      detectWidth,
      startMenuClosed,
      handleFocus
    };
  }
}
</script>

<style lang="sass" scoped>
  $white: #fff2f2
  $calmWhite: #E5E5E5
  $hoverBg: rgba(255, 255, 255, 0.25)

  $media-mobile: 400px

  #navbar
    padding: 1rem 1rem 0 1rem
    position: relative

    .logo
      position: absolute
      width: 36px

      img
        width: 100%

  .control-buttons 
    height: 100%
    display: flex
    justify-content: center
    z-index: 10

    h2
      display: none

    ul
      display: flex
      justify-content: center
      flex-wrap: wrap

      li
        list-style-type: none
        margin: 2px
        border-radius: 4px
        cursor: pointer
        font-color: $white
        font-weight: bold
        font-size: 1.2em
        overflow: hidden
        
        a
          display: block
          width: 5rem
          padding: 0.5rem
          text-align: center
          color: $white
          text-decoration: none
          font-size: 0.8em

          &:hover
            background: $hoverBg
          
          &.router-link-active
            border-bottom: 1px solid $calmWhite
            
            
  @media screen and (max-width: 450px)
    .nav-container
      width: 100vw
      z-index: 100

    .nav-opened
      height: 100%
      background: rgb(0 0 0 / 79%)
      position: fixed

    .buttonsArea
      width: 100%

    .control-buttons
      display: block

      h2
        display: block
        padding: 12px 0
        text-align: center
        color: rgba(177, 255, 157, 0.85)
        cursor: pointer

      .mobileDrop
        display: none
        
      ul
        flex-direction: column
        width: 100%
        align-items: center
        margin: 0px auto
      
        li
          width: -webkit-fill-available
          margin: 0
          
          a
            width: 100%

</style>