<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;700&family=Noto+Sans+JP:wght@300;400;700&display=swap');
  
  *, body, html {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Josefin Sans', 'Noto Sans JP', 'Roboto', sans;
  }
  #website {
    background-color: #69B7E0;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
  }
</style>