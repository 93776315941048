import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../components/pages/HomePage.vue';

const Portfolio = () => import(/* webpackChunkName: "portfolio" */ '../components/pages/PortfolioPage.vue');
const BioPage = () => import(/* webpackChunkName: "portfolio" */ '../components/pages/BioPage.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/bio', name: 'BioPage', component: BioPage },
    { path: '/portfolio',  name: 'Portfolio', component: Portfolio },
    { path: '/', name: 'Home', component: HomePage }
  ]
});

export default router;